import { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import { useGetSubscriptionByIdQuery, useUpdateSubscriptionMutation } from 'apis/subscriptions-admin.api';
import {
  CREATE_SUBSCRIPTION_DEFAULT_VALUES,
  SUBSCRIPTION_MANAGEMENT_TABS,
  SUBSCRIPTION_MANAGEMENT_VALIDATE_SCHEMA,
} from 'constants/subscriptions-admin';
import { useOpen } from 'hooks/useOpen';
import { BASE_RADIO_BUTTON_RADIO_VALUES, CommonError } from 'interfaces/shared.interface';
import {
  EditSubscriptionFormValues,
  SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES,
} from 'interfaces/subscriptions-admin.interface';
import { ErrorModal } from 'shared-components';
import ModalControlButtons from 'shared-components/ModalControlButtons';
import Tabs from 'shared-components/Tabs';
import { successNotify } from 'utils/notifications';
import { getCapitalizedValue, getValuesWithDecimals, parseNumberFormatInputToNumber } from 'utils/string';

interface Props {
  handleClose: VoidFunction;
  userId: number;
  refetch: VoidFunction;
}

const EditSubscriptionModalContent = ({ handleClose, userId, refetch }: Props) => {
  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const { data, isLoading: isGetSubscriptionLoading, isFetching } = useGetSubscriptionByIdQuery(userId.toString());
  const [updateSubscription, { error }] = useUpdateSubscriptionMutation();

  const { data: errorData } = (error as CommonError) || {};

  const { featureValues, subscription } = data?.result || {};

  const { reset, ...editSubscriptionsFormMethods } = useForm<EditSubscriptionFormValues>({
    resolver: yupResolver(SUBSCRIPTION_MANAGEMENT_VALIDATE_SCHEMA),
    defaultValues: CREATE_SUBSCRIPTION_DEFAULT_VALUES,
  });

  const onSubmit = async ({
    displayName,
    isPaid,
    dailyPrice,
    monthlyPrice,
    weeklyPrice,
    annualPrice,
    trialDayCount,
    waitingDayAfterExpire,
    expiringSubscriptionId,
    managingUsers,
    maxUserCount,
    statusId,
  }: EditSubscriptionFormValues) => {
    const payload = {
      featureValues: [
        {
          name: `App.${getCapitalizedValue(SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.MANAGING_USERS)}`,
          value: managingUsers.toString(),
        },
        {
          name: `App.${getCapitalizedValue(SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.MAX_USERS_COUNT)}`,
          value: maxUserCount,
        },
      ],
      subscription: {
        id: userId,
        statusId: statusId ? 2 : 1,
        displayName,
        isPaid: !!isPaid,
        dailyPrice: parseNumberFormatInputToNumber(dailyPrice),
        monthlyPrice: parseNumberFormatInputToNumber(monthlyPrice),
        weeklyPrice: parseNumberFormatInputToNumber(weeklyPrice),
        annualPrice: parseNumberFormatInputToNumber(annualPrice),
        trialDayCount: parseNumberFormatInputToNumber(trialDayCount),
        waitingDayAfterExpire: parseNumberFormatInputToNumber(waitingDayAfterExpire),
        expiringSubscriptionId: expiringSubscriptionId || null,
      },
    };

    try {
      await updateSubscription(payload).unwrap().then(refetch);
      handleClose();
      successNotify();
    } catch (error) {
      console.error(error);
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    if (!subscription || !featureValues) {
      return;
    }

    const getPriceWithDecimals = (price: number | null) => (price ? getValuesWithDecimals(price) : '-');
    const getStringValue = (value: number | null) => (value ? value.toString() : null);

    const newValues = {
      displayName: subscription.displayName,
      dailyPrice: getPriceWithDecimals(subscription.dailyPrice),
      monthlyPrice: getPriceWithDecimals(subscription.monthlyPrice),
      weeklyPrice: getPriceWithDecimals(subscription.weeklyPrice),
      annualPrice: getPriceWithDecimals(subscription.annualPrice),
      trialDayCount: getStringValue(subscription.trialDayCount),
      maxUserCount: featureValues[0].value,
      managingUsers: featureValues[1].value === 'true',
      waitingDayAfterExpire: getStringValue(subscription.waitingDayAfterExpire),
      statusId: subscription.statusId === 2,
      assignedSubscription: subscription.expiringSubscriptionId
        ? BASE_RADIO_BUTTON_RADIO_VALUES.YES
        : BASE_RADIO_BUTTON_RADIO_VALUES.NO,
      expiringSubscriptionId: subscription.expiringSubscriptionId,
    };

    reset(newValues);
  }, [featureValues, reset, subscription]);
  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <div data-cy="edit-subscription-modal">
        <FormProvider {...editSubscriptionsFormMethods} reset={reset}>
          <form onSubmit={editSubscriptionsFormMethods.handleSubmit(onSubmit)}>
            <Tabs
              tabs={SUBSCRIPTION_MANAGEMENT_TABS}
              componentProps={{ isEditingType: true, isLoading: isGetSubscriptionLoading, isFetching: isFetching }}
            />
            <ModalControlButtons
              isShownSubmit
              onClose={handleClose}
              isLoading={isGetSubscriptionLoading || isFetching}
            />
          </form>
        </FormProvider>
      </div>
    </>
  );
};

export default EditSubscriptionModalContent;
