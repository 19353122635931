import {
  GOCARDLESS_COLUMNS,
  GOCARDLESS_FILTERS_FORM_FIELD_NAMES,
  PAY_FORM_FIELDS,
  PAY_SUMMARY_FORM_FIELDS,
  PAYMENTS_COLUMNS,
  PaymentTypes,
} from 'interfaces/integrations/gocardless.interface';
import { FieldTypes, FormItem, TabItem, TableColumn } from 'interfaces/shared.interface';
import {
  MandateInformation,
  Payments,
  RepaymentInformation,
  ScheduleInformation,
} from 'page-components/integrations/gocardless/repayment-details/repayment-details-tabs/index';
import { InputNumberFormat, SelectFormInput, TextDivider } from 'shared-components';

import { BASE_FILTER_FIELDS } from './common';

export const GOCARDLESS_COLUMNS_TITLES = {
  [GOCARDLESS_COLUMNS.REPAYMENT_REF]: 'RepaymentRef',
  [GOCARDLESS_COLUMNS.PAYMENT_TYPE]: 'PaymentType',
  [GOCARDLESS_COLUMNS.AMOUNT]: 'Amount',
  [GOCARDLESS_COLUMNS.STATUS]: 'Status',
  [GOCARDLESS_COLUMNS.CREATION_TIME]: 'CreationTime',
  [GOCARDLESS_COLUMNS.ACTION]: '',
};

export const GOCARDLESS_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: GOCARDLESS_COLUMNS.REPAYMENT_REF,
    title: GOCARDLESS_COLUMNS_TITLES[GOCARDLESS_COLUMNS.REPAYMENT_REF],
    width: 'w-80',
  },
  {
    columnId: GOCARDLESS_COLUMNS.PAYMENT_TYPE,
    title: GOCARDLESS_COLUMNS_TITLES[GOCARDLESS_COLUMNS.PAYMENT_TYPE],
    width: 'w-80',
  },
  {
    columnId: GOCARDLESS_COLUMNS.AMOUNT,
    title: GOCARDLESS_COLUMNS_TITLES[GOCARDLESS_COLUMNS.AMOUNT],
    width: 'w-36',
  },
  {
    columnId: GOCARDLESS_COLUMNS.STATUS,
    title: GOCARDLESS_COLUMNS_TITLES[GOCARDLESS_COLUMNS.STATUS],
    width: 'w-36',
  },
  {
    columnId: GOCARDLESS_COLUMNS.CREATION_TIME,
    title: GOCARDLESS_COLUMNS_TITLES[GOCARDLESS_COLUMNS.CREATION_TIME],
    width: 'w-52',
    sorting: true,
  },
  {
    columnId: GOCARDLESS_COLUMNS.ACTION,
    title: GOCARDLESS_COLUMNS_TITLES[GOCARDLESS_COLUMNS.ACTION],
    width: 'w-1/12',
  },
];

const STATUS_FILTERS_OPTIONS = [
  { label: 'Created', value: '1' },
  { label: 'PartiallyPaid', value: '2' },
  { label: 'PaidInFull', value: '3' },
  { label: 'ReviewRequired', value: '4' },
];

const PAYMENT_TYPE_FILTERS_OPTIONS = [
  { label: 'InstantPayment', value: '1' },
  { label: 'DirectDebit', value: '2' },
  { label: 'DirectDebitWithInstantPayment', value: '3' },
];

export const GOCARDLESS_FILTER_FORM_FIELDS: FormItem[] = [
  {
    name: GOCARDLESS_FILTERS_FORM_FIELD_NAMES.STATUS,
    label: 'Status',
    className: 'ml-10 w-30',
    labelClassName: 'text-sm text-gray-500 dark:text-gray',
    type: FieldTypes.SELECT,
    dataCy: 'is-status-select',
    component: SelectFormInput,
    options: [...STATUS_FILTERS_OPTIONS],
    isFilter: true,
  },
  {
    name: GOCARDLESS_FILTERS_FORM_FIELD_NAMES.PAYMENT_TYPE,
    label: 'PaymentType',
    labelClassName: 'text-sm text-gray-500 dark:text-gray',
    className: 'ml-10 w-30',
    dataCy: 'is-payment-type-select',
    type: FieldTypes.SELECT,
    component: SelectFormInput,
    options: [...PAYMENT_TYPE_FILTERS_OPTIONS],
    isFilter: true,
  },
];

export const getGoCardlessFiltersFieldsDefaultValue = (selectStatusLabel: string, selectPaymentLabel: string) => ({
  [BASE_FILTER_FIELDS.SEARCH_FIELD]: '',
  [GOCARDLESS_FILTERS_FORM_FIELD_NAMES.STATUS]: { label: selectStatusLabel, value: '' },
  [GOCARDLESS_FILTERS_FORM_FIELD_NAMES.PAYMENT_TYPE]: { label: selectPaymentLabel, value: '' },
});

export const SEARCH_GOCARDLESS_PLACEHOLDER = 'SearchByReferenceId';

export const getRepaymentsTabs = (isMandate: boolean, isSchedule: boolean, isPayments: boolean): TabItem[] => [
  {
    id: 'repayment-information',
    title: 'RepaymentInformation',
    component: RepaymentInformation,
    dataCy: 'repayment-information-tab',
  },
  ...(isMandate
    ? [
        {
          id: 'mandate-information',
          title: 'MandateInformation',
          component: MandateInformation,
          dataCy: 'mandate-information-tab',
        },
      ]
    : []),
  ...(isSchedule
    ? [
        {
          id: 'schedule-information',
          title: 'ScheduleInformation',
          component: ScheduleInformation,
          dataCy: 'schedule-information-tab',
        },
      ]
    : []),
  ...(isPayments
    ? [
        {
          id: 'payments-information',
          title: 'Payments',
          component: Payments,
          dataCy: 'payments-information-tab',
        },
      ]
    : []),
];

export const PAYMENTS_COLUMNS_TITLES = {
  [PAYMENTS_COLUMNS.ORDER]: 'Order',
  [PAYMENTS_COLUMNS.AMOUNT]: 'Amount',
  [PAYMENTS_COLUMNS.STATUS]: 'Status',
  [PAYMENTS_COLUMNS.CREATED_DATE]: 'CreatedDate',
  [PAYMENTS_COLUMNS.CHARGE_DATE]: 'ChargeDate',
};

export const PAYMENTS_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: PAYMENTS_COLUMNS.ORDER,
    title: PAYMENTS_COLUMNS_TITLES[PAYMENTS_COLUMNS.ORDER],
  },
  {
    columnId: PAYMENTS_COLUMNS.AMOUNT,
    title: PAYMENTS_COLUMNS_TITLES[PAYMENTS_COLUMNS.AMOUNT],
  },
  {
    columnId: PAYMENTS_COLUMNS.STATUS,
    title: PAYMENTS_COLUMNS_TITLES[PAYMENTS_COLUMNS.STATUS],
  },
  {
    columnId: PAYMENTS_COLUMNS.CREATED_DATE,
    title: PAYMENTS_COLUMNS_TITLES[PAYMENTS_COLUMNS.CREATED_DATE],
  },
  {
    columnId: PAYMENTS_COLUMNS.CHARGE_DATE,
    title: PAYMENTS_COLUMNS_TITLES[PAYMENTS_COLUMNS.CHARGE_DATE],
  },
];

export const getPaymentTypeFormFields = (
  paymentTypeOptions?: PaymentTypes[],
  isInstantPayment?: boolean,
  isDirectDebit?: boolean
): FormItem[] => {
  return [
    {
      name: PAY_FORM_FIELDS.AMOUNT,
      label: 'Amount',
      type: FieldTypes.TEXT,
      required: true,
      inputClassName: 'w-full',
      dataCy: 'payment-amount-field',
      className: 'mb-4',
      component: InputNumberFormat,
      validation: {
        required: 'ThisFieldIsRequired',
      },
      decimalSeparator: '.',
      fixedDecimalLength: 2,
    },
    {
      name: PAY_FORM_FIELDS.PAYMENT_TYPE,
      label: 'PaymentType',
      type: FieldTypes.SELECT,
      required: true,
      inputClassName: 'w-full',
      dataCy: 'payment-payment-type-field',
      className: 'mb-4',
      component: SelectFormInput,
      validation: {
        required: 'ThisFieldIsRequired',
      },
      placeholder: 'SelectPaymentType',
      options: paymentTypeOptions?.map((payment) => ({
        label: payment.name,
        value: payment.id.toString(),
      })),
    },
    ...(isInstantPayment
      ? [
          {
            name: PAY_SUMMARY_FORM_FIELDS.TOTAL_AMOUNT,
            label: 'TotalAmount',
            type: FieldTypes.NUMBER,
            inputClassName: 'w-full',
            dataCy: 'payment-total-amount-field',
            className: 'mb-4',
            component: InputNumberFormat,
            disabled: true,
            fixedDecimalLength: 2,
            decimalSeparator: '.',
          },
        ]
      : []),
    ...(isDirectDebit
      ? [
          {
            name: PAY_FORM_FIELDS.LOAN_LENGTH_MONTHS,
            label: 'LoanTermMonths',
            type: FieldTypes.TEXT,
            inputClassName: 'w-full',
            dataCy: 'payment-loan-length-field',
            className: 'mb-4',
            component: InputNumberFormat,
            validation: {
              required: 'ThisFieldIsRequired',
            },
            required: true,
            isShownArrowButtons: true,
            minValue: '3',
            maxValue: 12,
            defaultValue: '3',
            fixedDecimalLength: 0,
          },
          {
            name: 'SUMMARY',
            className: 'mb-4',
            component: TextDivider,
            label: 'Summary',
          },
          {
            name: PAY_SUMMARY_FORM_FIELDS.TOTAL_AMOUNT,
            label: 'TotalAmount',
            type: FieldTypes.TEXT,
            inputClassName: 'w-full',
            dataCy: 'payment-total-amount-field',
            className: 'mb-4',
            component: InputNumberFormat,
            disabled: true,
            fixedDecimalLength: 2,
            decimalSeparator: '.',
          },
        ]
      : []),
    ...(isDirectDebit
      ? [
          {
            name: PAY_SUMMARY_FORM_FIELDS.FIRST_PAYMENT_AMOUNT,
            label: 'FirstPaymentAmount',
            type: FieldTypes.TEXT,
            inputClassName: 'w-full',
            dataCy: 'payment-first-payment-field',
            className: 'mb-4',
            component: InputNumberFormat,
            disabled: true,
            fixedDecimalLength: 2,
            decimalSeparator: '.',
          },
          {
            name: PAY_SUMMARY_FORM_FIELDS.RECURRING_PAYMENT_AMOUNT,
            label: 'RecurringPaymentAmount',
            type: FieldTypes.TEXT,
            inputClassName: 'w-full',
            dataCy: 'payment-recurring-payment-field',
            className: 'mb-4',
            component: InputNumberFormat,
            disabled: true,
            fixedDecimalLength: 2,
            decimalSeparator: '.',
          },
        ]
      : []),
  ];
};

export const DEFAULT_LOAN_LENGTH_MONTHS = 3;
export const MAX_LOAN_LENGTH_MONTHS = 12;
export const DIRECT_DEBIT_PAYMENT_TYPES = [2, 3];
export const INSTANT_PAYMENT_TYPE = 1;
