import { Layout } from 'react-grid-layout';

import { AdminTopStats } from 'interfaces/admin-top-starts.interface';
import {
  ADD_PAGE_FORM_FIELD_NAMES,
  ADD_WIDGET_FORM_FIELD_NAMES,
  TenantTopStats,
  TopStatsWidgetItem,
} from 'interfaces/dashboard.interface';
import { DateRange, FieldTypes, FormItem, OptionsTypeValue } from 'interfaces/shared.interface';
import { Input, SelectFormInput } from 'shared-components';

export enum TopStatItemVariant {
  WITH_DATA_OVER_BAR = 'withDataOverBar',
  WITH_DATA_IN_TITLE = 'withDataInTitle',
}

export enum DashboardWidgetItems {
  TOP_STATS_WIDGET_TENANT = 'Widgets_Tenant_TopStats',
  TOP_STATS_WIDGET_ADMIN = 'Widgets_Host_TopStats',
  INCOME_STATISTICS_WIDGET = 'Widgets_Host_IncomeStatistics',
  EXPIRING_TENANTS = 'Widgets_Host_SubscriptionExpiringTenants',
  RECENT_TENANTS = 'Widgets_Host_RecentTenants',
  SUBSCRIPTION_STATISTICS_WIDGET = 'Widgets_Host_SubscriptionStatistics',
  SALES_SUMMARY_WIDGET = 'Widgets_Tenant_SalesSummary',
  REGIONAL_STATS_WIDGET = 'Widgets_Tenant_RegionalStats',
  GENERAL_STATS_WIDGET = 'Widgets_Tenant_GeneralStats',
  DAILY_SALES_WIDGET = 'Widgets_Tenant_DailySales',
  PROFIT_SHARE_WIDGET = 'Widgets_Tenant_ProfitShare',
  MEMBER_ACTIVITY = 'Widgets_Tenant_MemberActivity',
}

export const DASHBOARD_WIDGETS_BOX_HEADER_CLASSNAME = '[&>h3]:text-1.5xl [&>h3]:mb-0 mb-7';

export const TENANT_TOP_STATS_WIDGET: TopStatsWidgetItem<TenantTopStats>[] = [
  {
    id: 'totalProfit',
    title: 'Total Profit',
    description: 'All Customs Value',
    isCurrency: true,
    valueClassName: 'text-blue2',
  },
  {
    id: 'newFeedbacks',
    title: 'New Feedbacks',
    description: 'Customer Review',
    valueClassName: 'text-green3',
    progressClassName: 'bg-green3',
  },
  {
    id: 'newOrders',
    title: 'New Orders',
    description: 'Fresh Order Amount',
    valueClassName: 'text-red',
    progressClassName: 'bg-red',
  },
  {
    id: 'newUsers',
    title: 'New Users',
    description: 'Joined New User',
    valueClassName: 'text-green4',
    progressClassName: 'bg-green4',
  },
];

export const ADMIN_TOP_STATS_WIDGET = ({ startDate, endDate }: DateRange): TopStatsWidgetItem<AdminTopStats>[] => [
  {
    id: 'newSubscriptionAmount',
    title: 'NewSubscriptionAmount',
    isCurrency: true,
    valueClassName: 'text-blue2',
    progressLabel: `${startDate} - ${endDate}`,
    progressLabelClassName: 'dark:text-white',
    progressLabelValueClassName: 'hidden',
  },
  {
    id: 'newTenantsCount',
    title: 'NewTenants',
    valueClassName: 'text-green3',
    progressLabel: `${startDate} - ${endDate}`,
    progressLabelClassName: 'dark:text-white',
    progressLabelValueClassName: 'hidden',
  },
  {
    id: 'dashboardPlaceholder1',
    title: 'DashboardSampleStatistics',
    valueClassName: 'text-red',
    progressClassName: 'bg-red',
    progressLabel: 'Progress',
  },
  {
    id: 'dashboardPlaceholder2',
    title: 'DashboardSampleStatistics',
    valueClassName: 'text-green4',
    progressClassName: 'bg-green4',
    progressLabel: 'Progress',
  },
];

export const TENANT_MOCKED_TOP_STATS_PERCENTS: Record<keyof TenantTopStats, number> = {
  newFeedbacks: 76,
  newOrders: 85,
  newUsers: 45,
  totalProfit: 57,
};

export const ADMIN_MOCKED_TOP_STATS_PERCENTS: Record<keyof AdminTopStats, number> = {
  newSubscriptionAmount: 100,
  newTenantsCount: 100,
  dashboardPlaceholder1: 45,
  dashboardPlaceholder2: 65,
};

export const DASHBOARD_GRID_BREAKPOINTS = (isAdmin: boolean): { [key: string]: Layout[] } => ({
  xxs: [
    {
      x: 0,
      y: 0,
      w: 1,
      h: 8,
      i: isAdmin ? DashboardWidgetItems.TOP_STATS_WIDGET_ADMIN : DashboardWidgetItems.TOP_STATS_WIDGET_TENANT,
    },
    { x: 0, y: 1, w: 1, h: 10, i: DashboardWidgetItems.INCOME_STATISTICS_WIDGET },
    { x: 0, y: 1, w: 1, h: 8, i: DashboardWidgetItems.SUBSCRIPTION_STATISTICS_WIDGET },
    { x: 0, y: 2, w: 1, h: 10, i: DashboardWidgetItems.EXPIRING_TENANTS },
    { x: 5, y: 2, w: 1, h: 10, i: DashboardWidgetItems.RECENT_TENANTS },
    { x: 0, y: 4, w: 1, h: 10, i: DashboardWidgetItems.SALES_SUMMARY_WIDGET },
    { x: 0, y: 4, w: 1, h: 10, i: DashboardWidgetItems.REGIONAL_STATS_WIDGET },
    { x: 0, y: 6, w: 1, h: 8, i: DashboardWidgetItems.GENERAL_STATS_WIDGET },
    { x: 0, y: 8, w: 1, h: 8, i: DashboardWidgetItems.DAILY_SALES_WIDGET },
    { x: 0, y: 10, w: 1, h: 11, i: DashboardWidgetItems.PROFIT_SHARE_WIDGET },
    { x: 0, y: 13, w: 1, h: 8, i: DashboardWidgetItems.MEMBER_ACTIVITY },
  ],
});

export const ADD_WIDGET_FORM_FIELDS = (options: OptionsTypeValue[]): FormItem[] => [
  {
    required: true,
    name: ADD_WIDGET_FORM_FIELD_NAMES.widget,
    type: FieldTypes.SELECT,
    dataCy: 'add-widget-select',
    component: SelectFormInput,
    options,
  },
];

export const ADD_PAGE_FORM_FIELDS: FormItem[] = [
  {
    required: true,
    name: ADD_PAGE_FORM_FIELD_NAMES.name,
    label: 'NewPageName',
    type: FieldTypes.TEXT,
    dataCy: 'add-page-name',
    component: Input,
  },
];

export const RENAME_PAGE_FORM_FIELDS = (namePlacehodler: string): FormItem[] => [
  {
    required: true,
    name: ADD_PAGE_FORM_FIELD_NAMES.name,
    label: 'PageNewName',
    placeholder: namePlacehodler,
    type: FieldTypes.TEXT,
    dataCy: 'rename-page-name',
    component: Input,
  },
];
