import { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { useLazyGetCurrentUserDetailsQuery, useUpdateUserDetailsMutation } from 'apis/user.api';
import { getProfileSettingsTabs, USER_NO_ROLES_MESSAGE } from 'constants/profile-settings';
import { useOpen } from 'hooks/useOpen';
import useIsTenant from 'hooks/useUserRole';
import { ProfileSettingsFormValues, UpdateProfileSettingsPayload } from 'interfaces/profile-settings.interface';
import { CommonError } from 'interfaces/shared.interface';
import { BASE_USER_SETTINGS_FORM_FIELDS, UserDetails } from 'interfaces/user.interface';
import { useAppSelector } from 'modules/store';
import { selectUserDetailsWithRoles } from 'modules/user/selector';
import { ErrorModal } from 'shared-components';
import Tabs from 'shared-components/Tabs';

import ProfileSettingsPasswordInputs from './ProfileSettingsPasswordInputs';

interface Props {
  handleClose: VoidFunction;
  handleNestedModalIsOpenedChange: (isNested: boolean) => void;
}

const ProfileSettingsModalContent = ({ handleClose, handleNestedModalIsOpenedChange }: Props) => {
  const [refetchUserDetails, { isLoading: isCurrentUserDetailsLoading }] = useLazyGetCurrentUserDetailsQuery();

  const [isErrorModalOpen, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const [updateUser, { error: updateUserDetailsError }] = useUpdateUserDetailsMutation();
  const errorData = (updateUserDetailsError as CommonError)?.data || {};

  const { user, roles, profilePictureId } = useAppSelector(selectUserDetailsWithRoles);

  const { isTenant } = useIsTenant();

  const { reset, ...profileSettingsFormMethods } = useForm<ProfileSettingsFormValues>({ reValidateMode: 'onSubmit' });

  const watchRoles = profileSettingsFormMethods?.watch(BASE_USER_SETTINGS_FORM_FIELDS.roles);
  const watchIsTwoFactorEnabled = profileSettingsFormMethods?.watch(BASE_USER_SETTINGS_FORM_FIELDS.isTwoFactorEnabled);

  const assignedRolesCount = Object.values(watchRoles || {}).filter((role) => role)?.length;

  const userId = user?.id;

  const onSubmit = async ({
    sendActivationEmail,
    roles,
    name,
    surname,
    password,
    phoneNumber,
    isTwoFactorEnabled,
    isLockoutEnabled,
    isActive,
    emailAddress,
    twoFactorAuthenticationTypeId,
  }: ProfileSettingsFormValues) => {
    const assignedRoleNames = Object.keys(roles).filter((key) => roles[key]);

    if (!assignedRoleNames?.length) {
      handleOpenErrorModal();
      return;
    }

    const payload = {
      sendActivationEmail,
      user: {
        ...user,
        name,
        surname,
        password,
        phoneNumber,
        isActive,
        isLockoutEnabled,
        isTwoFactorEnabled,
        emailAddress,
        sendActivationEmail,
        twoFactorAuthenticationTypeId,
      } as UserDetails,
      assignedRoleNames,
    } as UpdateProfileSettingsPayload;

    try {
      await updateUser(payload)
        .unwrap()
        .then(refetchUserDetails as VoidFunction);

      handleClose();
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    if (user) {
      const assignedRoles = roles?.reduce(
        (prevRoles, { roleName, isAssigned }) => ({ ...prevRoles, [roleName]: isAssigned }),
        {}
      );

      reset({ ...user, roles: assignedRoles });
    }
  }, [reset, user, roles]);

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleCloseErrorModal}
      />
      <FormProvider {...profileSettingsFormMethods} reset={reset}>
        <form onSubmit={profileSettingsFormMethods.handleSubmit(onSubmit)}>
          <Tabs
            tabs={getProfileSettingsTabs(assignedRolesCount, watchIsTwoFactorEnabled, isTenant)}
            componentProps={{
              userId,
              roles,
              profilePictureId,
              handleClose,
              customComponent: ProfileSettingsPasswordInputs,
              isCurrentUserDetailsLoading,
              handleNestedModalIsOpenedChange,
            }}
          />
        </form>
      </FormProvider>
      <ErrorModal errorMessage={USER_NO_ROLES_MESSAGE} isOpen={isErrorModalOpen} handleClose={handleCloseErrorModal} />
    </>
  );
};

export default ProfileSettingsModalContent;
