import { useState } from 'react';

import { FormProvider, Controller, useForm, FieldValues, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { usePostResendMutation } from 'apis/email-history.api';
import { useOpen } from 'hooks/useOpen';
import { EmailHistory, ResendPayload, UserAdminData } from 'interfaces/email-history.interface';
import { CommonError } from 'interfaces/shared.interface';
import { ErrorModal, Modal, SelectFormInput } from 'shared-components';
import ModalControlButtons from 'shared-components/ModalControlButtons';
import { successNotify } from 'utils/notifications';

interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
  userAdminData?: UserAdminData[];
  historyData: EmailHistory;
}

const EmailResendModal = ({ isOpen, onClose, userAdminData, historyData }: Props) => {
  const { t } = useTranslation();

  const [isOpenErrorModal, handleOnOpenErrorModal, handleOnCloseErrorModal] = useOpen();
  const [resend, { isLoading: isResendLoading, error: renewSubscriptionError }] = usePostResendMutation();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const receiverEmail = historyData.receiverEmail;

  const resendEmailFormMethods = useForm<FieldValues>({
    mode: 'onChange',
    defaultValues: {
      receiverEmail: {
        label: receiverEmail,
        value: receiverEmail,
      },
    },
  });

  const { handleSubmit, control, reset } = resendEmailFormMethods;

  const options =
    userAdminData?.map((status) => ({
      label: status.emailAddress,
      value: status.emailAddress,
    })) || [];

  const errorData = (renewSubscriptionError as CommonError)?.data?.error;

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const payload: ResendPayload = {
      id: historyData.id,
      ...(data.receiverEmail?.label !== receiverEmail && {
        newReceiverEmail: data.receiverEmail.label,
      }),
    };

    try {
      await resend(payload).unwrap();
      successNotify('SentSuccessfully');
      onClose();
    } catch (error) {
      handleOnOpenErrorModal();
    }
  };

  const handleCloseWithReset = () => {
    reset({
      receiverEmail: {
        label: receiverEmail,
        value: receiverEmail,
      },
    });
    onClose();
  };

  const handleBlur = (field: FieldValues) => {
    if (!field.value) {
      field.onChange({
        label: field.emailAddress,
        value: field.emailAddress,
      });
    }

    setTimeout(() => {
      setIsDropdownOpen(false);
    }, 100);
  };

  const handleFocus = () => {
    setIsDropdownOpen(true);
  };

  return (
    <>
      <ErrorModal
        errorMessage={errorData?.message}
        description={errorData?.details}
        isOpen={isOpenErrorModal}
        handleClose={handleOnCloseErrorModal}
      />
      <Modal
        dataCy="resend-modal"
        heading="Resend"
        isDisableOutsideClick
        isOpen={isOpen}
        onClose={handleCloseWithReset}
      >
        <FormProvider {...resendEmailFormMethods}>
          <form onSubmit={handleSubmit(onSubmit)} data-cy="resend-form">
            <div className="w-full text-sm my-4">{t('ReceiverEmail')}:</div>
            <Controller
              name="receiverEmail"
              control={control}
              render={({ field }) => (
                <>
                  <SelectFormInput
                    {...field}
                    control={control}
                    options={options}
                    isSearchable
                    required
                    disabled={!userAdminData}
                    onChange={(selected) => field.onChange(selected)}
                    isLoading={isResendLoading}
                    setIsDropdownOpen={setIsDropdownOpen}
                    onBlur={() => handleBlur(field)}
                    onFocus={handleFocus}
                  />
                  <ModalControlButtons
                    isLoading={isResendLoading}
                    saveButtonText="Send"
                    onClose={handleCloseWithReset}
                    closeButtonText="Close"
                    onSubmit={handleSubmit(onSubmit)}
                    disabled={isDropdownOpen || !userAdminData?.some((user) => user.emailAddress === field.value.label)}
                  />
                </>
              )}
            />
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default EmailResendModal;
