import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTenantGetGeneralStatsQuery } from 'apis';
import { BoxVariants, ButtonVariants } from 'constants/common';
import { DASHBOARD_WIDGETS_BOX_HEADER_CLASSNAME } from 'constants/dashboard';
import { GENERAL_STATS_METRICS } from 'constants/general-stats';
import { GeneralStats } from 'interfaces/general-stats.interface';
import { Box, Button, LoadingOverlay } from 'shared-components';

import GeneralStatsProgressItem from './GeneralStatsProgressItem';

export const GeneralStatsWidget = () => {
  const { data: generalStatsData, isLoading, isFetching, refetch } = useTenantGetGeneralStatsQuery();

  const generalStats = generalStatsData?.result || ({} as GeneralStats);

  const totalGeneralStatsValue = Object.values(generalStats).reduce((acc, value) => acc + value, 0);

  return (
    <Box
      dataCy="general-stats-widget"
      className="relative h-full overflow-auto"
      headerClassName={DASHBOARD_WIDGETS_BOX_HEADER_CLASSNAME}
      variant={BoxVariants.ROUNDED}
      title="WidgetGeneralStats"
      customHeader={
        <Button variant={ButtonVariants.MAIN} onClick={refetch} dataCy="general-stats-refresh">
          <FontAwesomeIcon icon={faRedo} /> Refresh
        </Button>
      }
    >
      <LoadingOverlay isLoading={isLoading || isFetching} />
      <div className="flex flex-wrap lg:justify-between gap-y-4">
        {GENERAL_STATS_METRICS?.map(({ id, dataCy, ...props }) => (
          <div className="flex flex-col items-center w-full sm:w-1/2 lg:w-auto" key={id} data-cy={dataCy}>
            <GeneralStatsProgressItem id={id} value={generalStats?.[id]} total={totalGeneralStatsValue} {...props} />
            <p data-cy="general-stats-progress-total" className="text-xs text-darkGray dark:text-white mt-2 lg:mt-1">
              Total: {generalStats?.[id]}
            </p>
          </div>
        ))}
      </div>
    </Box>
  );
};
