import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { useAdminGetExpiringTenantsQuery } from 'apis';
import { ButtonVariants } from 'constants/common';
import { EXPIRING_TENANTS_COLUMNS } from 'constants/expiring-tenants';
import { ROUTES } from 'constants/routes';
import { Box, LinkButton, LoadingOverlay, Table } from 'shared-components';
import { getRandomColor } from 'utils/global';

import { ExpiringTenantsTableRow } from './ExpiringTenantsTableRow';

export const ExpiringTenants = () => {
  const { t } = useTranslation();

  const { data: expiringTenantsData, isLoading, isFetching } = useAdminGetExpiringTenantsQuery();
  const expiringTenants = expiringTenantsData?.result.expiringTenants;

  return (
    <Box
      dataCy="expiring-tenants-widget"
      className="relative flex flex-col gap-4 h-full overflow-auto"
      title="WidgetSubscriptionExpiringTenants"
      titleClassName="mb-0"
      headerClassName="text-red1 flex-row-reverse justify-end items-baseline gap-2"
      headerWrapperClassName="flex flex-col md:flex-row items-baseline gap-2"
      customHeader={<FontAwesomeIcon icon={faClock} className="fill-none stroke-red1 self-start" />}
      customSubHeader={<p className="text-xs text-gray2">{t('TenantsExpiringInDays', { 0: 10, 1: 30 })}</p>}
    >
      <LoadingOverlay isLoading={isLoading || isFetching} />
      <Table
        wrapperClassName="flex-1 overflow-x-auto"
        className="mt-7"
        dataCy="expiring-tenants-table"
        columns={EXPIRING_TENANTS_COLUMNS}
        isTableEmpty={!expiringTenants?.length}
        isLoading={isLoading}
      >
        {expiringTenants?.map((tenant) => (
          <ExpiringTenantsTableRow
            key={`${tenant.tenantName}-${getRandomColor()}-${tenant.remainingDayCount}`}
            tenant={tenant}
          />
        ))}
      </Table>
      <LinkButton
        className="w-fit self-end text-sm"
        variant={ButtonVariants.LINK}
        to={ROUTES.tenants}
        data-cy="expiring-tenants-see-all"
      >
        {t('SeeAllRecords')}
      </LinkButton>
    </Box>
  );
};
