import {
  EMAIL_HISTORY_COLUMNS,
  EMAIL_HISTORY_FILTERS_FORM_FIELD_NAMES,
  STATUS_ENUMS,
} from 'interfaces/email-history.interface';
import { FieldTypes, FormItem, OptionType, TableColumn } from 'interfaces/shared.interface';
import { SelectFormInput } from 'shared-components';

import { BASE_FILTER_FIELDS } from './common';

export const EMAIL_HISTORY_COLUMNS_TITLES = {
  [EMAIL_HISTORY_COLUMNS.SUBJECT]: 'TemplateName',
  [EMAIL_HISTORY_COLUMNS.SENDER]: 'CreationTime',
  [EMAIL_HISTORY_COLUMNS.RECEIVER]: 'Receiver',
  [EMAIL_HISTORY_COLUMNS.RECEIVER_EMAIL]: 'ReceiverEmail',
  [EMAIL_HISTORY_COLUMNS.RECEIVER_COMPANY]: 'ReceiverCompany',
  [EMAIL_HISTORY_COLUMNS.IS_SENSITIVE]: 'IsSensitive',
  [EMAIL_HISTORY_COLUMNS.STATUS]: 'Status',
  [EMAIL_HISTORY_COLUMNS.CREATION_TIME]: 'CreationTime',
  [EMAIL_HISTORY_COLUMNS.ACTIONS]: '',
};

export const EMAIL_HISTORY_TABLE_COLUMNS: TableColumn[] = [
  {
    columnId: EMAIL_HISTORY_COLUMNS.SUBJECT,
    title: EMAIL_HISTORY_COLUMNS_TITLES[EMAIL_HISTORY_COLUMNS.SUBJECT],
  },
  {
    columnId: EMAIL_HISTORY_COLUMNS.SENDER,
    title: EMAIL_HISTORY_COLUMNS_TITLES[EMAIL_HISTORY_COLUMNS.SENDER],
  },
  {
    columnId: EMAIL_HISTORY_COLUMNS.RECEIVER,
    title: EMAIL_HISTORY_COLUMNS_TITLES[EMAIL_HISTORY_COLUMNS.RECEIVER],
  },
  {
    columnId: EMAIL_HISTORY_COLUMNS.RECEIVER_EMAIL,
    title: EMAIL_HISTORY_COLUMNS_TITLES[EMAIL_HISTORY_COLUMNS.RECEIVER_EMAIL],
  },
  {
    columnId: EMAIL_HISTORY_COLUMNS.RECEIVER_COMPANY,
    title: EMAIL_HISTORY_COLUMNS_TITLES[EMAIL_HISTORY_COLUMNS.RECEIVER_COMPANY],
  },
  {
    columnId: EMAIL_HISTORY_COLUMNS.IS_SENSITIVE,
    title: EMAIL_HISTORY_COLUMNS_TITLES[EMAIL_HISTORY_COLUMNS.IS_SENSITIVE],
  },
  {
    columnId: EMAIL_HISTORY_COLUMNS.STATUS,
    title: EMAIL_HISTORY_COLUMNS_TITLES[EMAIL_HISTORY_COLUMNS.STATUS],
  },
  {
    columnId: EMAIL_HISTORY_COLUMNS.CREATION_TIME,
    title: EMAIL_HISTORY_COLUMNS_TITLES[EMAIL_HISTORY_COLUMNS.CREATION_TIME],
    sorting: true,
  },
  { columnId: EMAIL_HISTORY_COLUMNS.ACTIONS, title: EMAIL_HISTORY_COLUMNS_TITLES[EMAIL_HISTORY_COLUMNS.ACTIONS] },
];

export const EMAIL_HISTORY_SEARCH_PLACEHOLDER = 'SearchByEmailSubject';

export const STATUS_DATA = [
  { id: 1, name: STATUS_ENUMS.CREATED },
  { id: 2, name: STATUS_ENUMS.PROCESSED },
  { id: 3, name: STATUS_ENUMS.FAILED },
];

export const IS_SENSITIVE_FILTERS_OPTIONS = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

export const getEmailHistoryFilterFormFields = (
  optionSelectStatus?: OptionType[],
  optionSelectReceiverEmail?: OptionType[]
): FormItem[] => [
  {
    name: EMAIL_HISTORY_FILTERS_FORM_FIELD_NAMES.IS_SENSITIVE,
    label: 'IsSensitive',
    className: 'ml-10 w-40',
    labelClassName: 'text-sm text-gray-500 dark:text-gray',
    type: FieldTypes.SELECT,
    dataCy: 'is-sensitive-select',
    component: SelectFormInput,
    options: [...IS_SENSITIVE_FILTERS_OPTIONS],
  },
  {
    name: EMAIL_HISTORY_FILTERS_FORM_FIELD_NAMES.SELECT_STATUS,
    label: 'SelectStatus',
    className: 'ml-10 w-40',
    labelClassName: 'text-sm text-gray-500 dark:text-gray',
    type: FieldTypes.SELECT,
    dataCy: 'select-status-select',
    component: SelectFormInput,
    options: optionSelectStatus,
  },
  {
    name: EMAIL_HISTORY_FILTERS_FORM_FIELD_NAMES.SELECT_RECEIVER_EMAIL,
    label: 'SelectReceiverEmail',
    className: 'ml-10 w-60',
    labelClassName: 'text-sm text-gray-500 dark:text-gray',
    type: FieldTypes.SELECT,
    dataCy: 'select-receiver-email-select',
    component: SelectFormInput,
    options: optionSelectReceiverEmail,
    isSearchable: true,
  },
];

export const getEmailHistoryFiltersFieldsDefaultValues = (
  isSensitiveLabel: string,
  selectStatusLabel: string,
  selectReceiverEmailLabel: string
) => ({
  [BASE_FILTER_FIELDS.SEARCH_FIELD]: '',
  [EMAIL_HISTORY_FILTERS_FORM_FIELD_NAMES.IS_SENSITIVE]: { label: isSensitiveLabel, value: '' },
  [EMAIL_HISTORY_FILTERS_FORM_FIELD_NAMES.SELECT_STATUS]: { label: selectStatusLabel, value: '' },
  [EMAIL_HISTORY_FILTERS_FORM_FIELD_NAMES.SELECT_RECEIVER_EMAIL]: { label: selectReceiverEmailLabel, value: '' },
});
