import { forwardRef, FunctionComponent, InputHTMLAttributes, isValidElement, ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

import { CheckboxVariants, CheckboxVariantsStyles } from 'constants/checkbox';
import { useOpen } from 'hooks/useOpen';
import { ErrorMessage, Skeleton } from 'shared-components';
import { cn } from 'utils/global';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: FunctionComponent<unknown> | string | ReactElement;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  inputId?: string;
  labelHtmlFor?: string;
  name?: string;
  value?: string;
  dataCy?: string;
  error?: string;
  tooltipContent?: string;
  isLoading?: boolean;
  variant?: CheckboxVariants;
}

const Checkbox = forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      error,
      inputClassName,
      labelClassName,
      className,
      labelHtmlFor,
      name,
      dataCy,
      onChange,
      tooltipContent,
      isLoading,
      variant = CheckboxVariants.PRIMARY,
      ...props
    },
    ref
  ) => {
    const [isShownTooltip, handleShowTooltip, handleCloseTooltip] = useOpen();

    const { t } = useTranslation();

    const renderedLabel = (): ReactElement | string => {
      if (!label) {
        return '';
      }

      switch (typeof label) {
        case 'function':
          const Component = label as FunctionComponent;
          return <Component />;

        case 'object':
          if (isValidElement(label)) {
            return label;
          }
          break;

        case 'string':
          return t(label);

        default:
          return '';
      }

      return '';
    };

    const inputStyles =
      'dark:checked:border-darkBlue7 dark:hover:checked:border-darkBlue7 dark:checked:bg-darkBlue7 dark:hover:checked:bg-darkBlue7 dark:hover:outline dark:hover:outline-white dark:border-white dark:hover:outline-1 dark:hover:checked:outline-0';

    return (
      <div data-cy={dataCy} className={className}>
        <label
          id={name}
          onMouseEnter={handleShowTooltip}
          onMouseLeave={handleCloseTooltip}
          htmlFor={labelHtmlFor}
          className={cn('flex items-center font-semibold cursor-pointer dark:text-gray w-fit', labelClassName, {
            'pointer-events-none': props.disabled,
          })}
        >
          {isLoading ? (
            <Skeleton className={cn(inputClassName, inputStyles)} />
          ) : (
            <input
              type="checkbox"
              name={name}
              onChange={onChange}
              ref={ref}
              {...props}
              className={cn(inputStyles, CheckboxVariantsStyles[variant], inputClassName)}
            />
          )}
          {label && <span className={cn('ml-2 text-sm', { 'opacity-70': props.disabled })}>{renderedLabel()}</span>}
        </label>

        {error && <ErrorMessage>{t(error)}</ErrorMessage>}
        {tooltipContent && (
          <Tooltip
            anchorId={name}
            positionStrategy="fixed"
            place="right"
            className="max-w-[170px] font-light"
            isOpen={isShownTooltip}
            clickable
          >
            {t(tooltipContent)}
          </Tooltip>
        )}
      </div>
    );
  }
);

export default Checkbox;

Checkbox.displayName = 'Checkbox';
