import { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGetEmailHistoryQuery, useGetEmailHistoryStatusesQuery, useGetUserAdminQuery } from 'apis/email-history.api';
import {
  EMAIL_HISTORY_SEARCH_PLACEHOLDER,
  EMAIL_HISTORY_TABLE_COLUMNS,
  getEmailHistoryFilterFormFields,
  getEmailHistoryFiltersFieldsDefaultValues,
} from 'constants/email-histoy';
import { useFilters } from 'hooks/useFilters';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useTableSorting } from 'hooks/useTableSorting';
import { EmailHistoryFilterFormValues } from 'interfaces/email-history.interface';
import { CommonError } from 'interfaces/shared.interface';
import EmailHistoryRow from 'page-components/email-history/EmailHistoryRow';
import {
  ErrorModal,
  FilterForm,
  FilterFormContentWithoutPermissions,
  LoadingOverlay,
  Pagination,
  Table,
} from 'shared-components';

const EmailHistory = () => {
  const { t } = useTranslation();

  const [isOpenErrorModal, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const { applyFilters, filterParams, isSameFilters } = useFilters();
  const { handleSetTabName, sortingType, sortingColumnId, sortingParams } = useTableSorting();
  const { paginationParams, handleSetMaxResultCount, handleSetCurrentPage, currentPage, maxResultCount } =
    usePagination();

  const {
    data,
    isLoading: isGetEmailHistoryLoading,
    isFetching,
    refetch,
    error: errorHistoryData,
  } = useGetEmailHistoryQuery(`${filterParams}${sortingParams}${paginationParams}`);

  const {
    data: historyStatusData,
    isLoading: isHistoryStatusLoading,
    error: errorHistoryStatusData,
  } = useGetEmailHistoryStatusesQuery();

  const { data: userAdminData, isLoading: isUserAdminLoading, error: errorUserAdminData } = useGetUserAdminQuery();

  const isLoading = isGetEmailHistoryLoading || isHistoryStatusLoading || isUserAdminLoading;
  const { totalCount, items } = data?.result || {};

  const { error: errorData } =
    (errorHistoryData as CommonError)?.data ||
    (errorHistoryStatusData as CommonError)?.data ||
    (errorUserAdminData as CommonError)?.data ||
    {};

  const historyFormMethods = useForm<EmailHistoryFilterFormValues>({
    defaultValues: getEmailHistoryFiltersFieldsDefaultValues(
      t('IsSensitive'),
      t('SelectStatus'),
      t('SelectReceiverEmail')
    ),
  });

  const onSubmitFilters = (data: EmailHistoryFilterFormValues) => {
    const { IsSensitive, SelectReceiverEmail, SelectStatus, filter } = data;

    const newFiltersToApply = {
      Filter: filter,
      IsSensitive: IsSensitive.value,
      ReceiverId: SelectReceiverEmail.value,
      StatusId: SelectStatus.value,
    };

    if (isSameFilters(newFiltersToApply)) {
      refetch();
    } else {
      applyFilters(newFiltersToApply);
    }
  };

  const handleClearFields = () => {
    historyFormMethods.reset(
      getEmailHistoryFiltersFieldsDefaultValues(t('IsSensitive'), t('SelectStatus'), t('SelectReceiverEmail'))
    );
    onSubmitFilters(
      getEmailHistoryFiltersFieldsDefaultValues(t('IsSensitive'), t('SelectStatus'), t('SelectReceiverEmail'))
    );
  };

  const isTableEmpty = !items?.length;

  useEffect(() => {
    if (errorData) {
      handleOpenErrorModal();
    }
  }, [errorData, handleOpenErrorModal]);

  return (
    <div data-cy="email-history-page">
      <LoadingOverlay isLoading={isFetching && !!data} />

      <div data-cy="email-history-filtering">
        <FormProvider {...historyFormMethods}>
          <form onSubmit={historyFormMethods.handleSubmit(onSubmitFilters)}>
            <FilterForm
              dataCy="filtering-input"
              placeHolder={EMAIL_HISTORY_SEARCH_PLACEHOLDER}
              handleClearFields={handleClearFields}
            >
              <FilterFormContentWithoutPermissions
                className="flex"
                fields={getEmailHistoryFilterFormFields(
                  historyStatusData?.result.map((status) => ({
                    label: status.name,
                    value: status.id,
                  })),
                  userAdminData?.result.map((status) => ({
                    label: status.emailAddress,
                    value: status.id,
                  }))
                )}
              />
            </FilterForm>
          </form>
        </FormProvider>
      </div>

      <Table
        dataCy="email-history-table"
        columns={EMAIL_HISTORY_TABLE_COLUMNS}
        handleSetTabName={handleSetTabName}
        sortingColumnId={sortingColumnId}
        sortingType={sortingType}
        isTableEmpty={isTableEmpty}
        isLoading={isLoading}
      >
        {items?.map((item) => (
          <EmailHistoryRow key={item.id} historyData={item} userAdminData={userAdminData?.result} />
        ))}
      </Table>

      <Pagination
        totalCount={totalCount}
        handleSetMaxResultCount={handleSetMaxResultCount}
        maxResultCount={maxResultCount}
        handleSetCurrentPage={handleSetCurrentPage}
        currentPage={currentPage}
      />

      <ErrorModal
        isOpen={isOpenErrorModal}
        handleClose={handleCloseErrorModal}
        errorMessage={errorData?.message}
        description={errorData?.details}
      />
    </div>
  );
};

export default EmailHistory;
