import { ElementType, PropsWithChildren } from 'react';

import { useFormContext } from 'react-hook-form';

import { useGetUserProfilePictureQuery } from 'apis/user.api';
import {
  getProfileInformationSettingsFormFields,
  PROFILE_INFORMATION_SETTINGS_FIELDS,
  PROFILE_NAMES_FORM_FIELDS,
} from 'constants/profile-settings';
import { useAppSelector } from 'modules/store';
import { selectTenantId } from 'modules/user/selector';
import { FormContent } from 'shared-components';
import ModalControlButtons from 'shared-components/ModalControlButtons';
import { joinMimeTypeAndDataToBase64 } from 'utils/files';

import ProfileInformationAvatarSettings from './profile-settings-avatar/ProfileInformationAvatarSettings';

interface Props {
  customComponent?: ElementType;
  userId?: number;
  profilePictureId?: string;
  handleClose: VoidFunction;
  handleNestedModalIsOpenedChange: (isNested: boolean) => void;
  isLoading: boolean;
}

const ProfileInformationSettings = ({
  customComponent: CustomComponent,
  userId,
  profilePictureId,
  handleClose,
  handleNestedModalIsOpenedChange,
  isLoading,
}: PropsWithChildren<Props>) => {
  const tenantId = useAppSelector(selectTenantId);

  const { data: pictureData, isLoading: isProfilePictureLoading } = useGetUserProfilePictureQuery(
    { profilePictureId: profilePictureId || '', tenantId: tenantId || 0 },
    {
      skip: !profilePictureId,
    }
  );
  const { mimeType, profilePicture } = pictureData?.result || {};

  const userPicture = joinMimeTypeAndDataToBase64({ mimeType, data: profilePicture });

  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <div className="px-5">
        <div className="flex flex-col xs:flex-row xs:space-x-4 pt-[14px]">
          <ProfileInformationAvatarSettings
            isProfilePictureLoading={isProfilePictureLoading}
            userId={userId}
            userPicture={profilePictureId ? userPicture : null}
            handleNestedModalIsOpenedChange={handleNestedModalIsOpenedChange}
          />
          <div className="basis-3/4 xs:mb-4">
            <FormContent isLoading={isLoading} fields={PROFILE_NAMES_FORM_FIELDS} register={register} errors={errors} />
          </div>
        </div>
        <div>
          <FormContent
            isLoading={isLoading}
            className="mb-4"
            fields={PROFILE_INFORMATION_SETTINGS_FIELDS}
            register={register}
            errors={errors}
          />

          {CustomComponent && <CustomComponent />}

          <FormContent
            isLoading={isLoading}
            className="mb-4"
            fields={getProfileInformationSettingsFormFields()}
            register={register}
            errors={errors}
          />
        </div>
      </div>
      <ModalControlButtons isShownSubmit className="pb-5" isLoading={isLoading} onClose={handleClose} />
    </>
  );
};

export default ProfileInformationSettings;
