import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTenantGetMemberActivityQuery } from 'apis';
import { BoxVariants, ButtonVariants } from 'constants/common';
import { MEMBER_ACTIVITY_COLUMNS } from 'constants/member-activity';
import { TableVariants } from 'constants/table';
import { Box, Button, LoadingOverlay, Table } from 'shared-components';

import { MemberActivityTableRow } from './MemberActivityTableRow';

export const MemberActivity = () => {
  const { data: memberActivityData, isLoading, isFetching, refetch } = useTenantGetMemberActivityQuery();
  const memberActivities = memberActivityData?.result.memberActivities;

  return (
    <Box
      dataCy="member-activity-widget"
      className="relative h-full overflow-auto"
      variant={BoxVariants.ROUNDED}
      title="WidgetMemberActivity"
      titleClassName="mb-0"
      customHeader={
        <Button
          variant={ButtonVariants.MAIN}
          className="whitespace-nowrap"
          onClick={refetch}
          dataCy="member-activity-refresh"
        >
          <FontAwesomeIcon icon={faRedo} /> Refresh
        </Button>
      }
    >
      <LoadingOverlay isLoading={isLoading || isFetching} />
      <Table
        className="mt-7"
        variant={TableVariants.SECONDARY}
        dataCy="member-activity-table"
        columns={MEMBER_ACTIVITY_COLUMNS}
        isTableEmpty={!memberActivities?.length}
        isLoading={isLoading}
      >
        {memberActivities?.map((activity) => (
          <MemberActivityTableRow key={`${activity.name}-${activity.cases}`} activity={activity} />
        ))}
      </Table>
    </Box>
  );
};
