import { Locale, format, formatDistanceToNow, parseISO, startOfDay, addDays, subDays } from 'date-fns';

import { DATE_LOCALES, DAY_FORMAT_WITH_SLASHES, DAY_FORMAT_WITH_TIME } from 'constants/global';

export const getFormattedDate = (date?: string | null, dateFormat?: string, language?: Locale) => {
  if (!date) {
    return '';
  }
  const chosenLanguage = language || DATE_LOCALES['en-GB'];
  return format(parseISO(date), dateFormat || DAY_FORMAT_WITH_SLASHES, { locale: chosenLanguage });
};

export const getFormattedDateLocalizedDistanceToNow = (date: string, locale?: string) => {
  if (!date) {
    return '';
  }

  return `${formatDistanceToNow(date, {
    addSuffix: true,
    locale: DATE_LOCALES[locale || 'en'],
  })} (${getFormattedDate(date, DAY_FORMAT_WITH_TIME)})`;
};

export const isDateTime = (value: string): boolean => {
  const timestamp = Date.parse(value);
  return !isNaN(timestamp);
};

export const getDateRangeForLastSevenDays = () => {
  const endDate = startOfDay(addDays(new Date(), 1));
  const startDate = subDays(endDate, 8);

  return { startDate, endDate };
};
