import { createSlice } from '@reduxjs/toolkit';

import { DashboardState } from 'interfaces/dashboard.interface';
import { getDateRangeForLastSevenDays } from 'utils/dates';

import { setDateRangeReducer, setIsEditModeReducer } from './reducers';

const { startDate, endDate } = getDateRangeForLastSevenDays();

const initialState: DashboardState = {
  isEditMode: false,
  dateRange: {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  },
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    setIsEditModeReducer(builder);
    setDateRangeReducer(builder);
  },
});

export default dashboardSlice.reducer;
