import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useRenamePageMutation } from 'apis';
import { RENAME_PAGE_FORM_FIELDS } from 'constants/dashboard';
import { useOpen } from 'hooks/useOpen';
import useUserRole from 'hooks/useUserRole';
import { RenamePageForm } from 'interfaces/dashboard.interface';
import { CommonError } from 'interfaces/shared.interface';
import { Button, ErrorModal, FormContent } from 'shared-components';
import { getDashboardName } from 'utils/dashboard';
import { successNotify } from 'utils/notifications';

interface Props {
  pageId: string;
  pageName: string;
}

export const RenamePage = ({ pageId, pageName }: Props) => {
  const { t } = useTranslation();

  const { isAdmin } = useUserRole();
  const dashboardName = getDashboardName(isAdmin);

  const [isOpenErrorModal, handleOpenErrorModal, handleCloseErrorModal] = useOpen();

  const [renamePageRequest, { isLoading, error }] = useRenamePageMutation();
  const { data: errorData } = (error as CommonError) || {};

  const renamePageMethods = useForm<RenamePageForm>();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = renamePageMethods;

  const onSubmit = async ({ name }: RenamePageForm) => {
    try {
      await renamePageRequest({ name, dashboardName, id: pageId }).unwrap();
      successNotify(t('SavedSuccessfully'));
    } catch (error) {
      handleOpenErrorModal();
    }
  };

  return (
    <FormProvider {...renamePageMethods}>
      <form className="flex flex-col gap-2" data-cy="rename-page-popup-form" onSubmit={handleSubmit(onSubmit)}>
        <FormContent fields={RENAME_PAGE_FORM_FIELDS(pageName)} register={register} errors={errors} />
        <Button isLoading={isLoading} dataCy="submit-button">
          {t('Save')}
        </Button>
      </form>
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isOpenErrorModal}
        handleClose={handleCloseErrorModal}
      />
    </FormProvider>
  );
};
