import { NO_DATA } from 'constants/common';
import { STATUS_DATA } from 'constants/email-histoy';
import { DAY_FORMAT_WITH_TIME } from 'constants/global';
import { useOpen } from 'hooks/useOpen';
import { EmailHistory, UserAdminData } from 'interfaces/email-history.interface';
import { Permissions } from 'interfaces/user.interface';
import { useAppSelector } from 'modules/store';
import { selectUserPermissions } from 'modules/user/selector';
import { Checkbox, DropdownMenu, TableData, TableRow } from 'shared-components';
import { getFormattedDate } from 'utils/dates';

import EmailDetailModal from './EmailDetailModal';
import EmailResendModal from './EmailResendModal';

interface Props {
  historyData: EmailHistory;
  userAdminData?: UserAdminData[];
}

const EmailHistoryRow = ({ historyData, userAdminData }: Props) => {
  const permissions = useAppSelector(selectUserPermissions);

  const [isDetailModalOpen, handleDetailModalOpen, handleDetailModalClose] = useOpen();
  const [isResendModalOpen, handleResendModalOpen, handleResendModalClose] = useOpen();

  const menuItemsAction = [
    {
      dataCy: 'menu-item-details-button',
      name: 'Details',
      actionOnClick: () => {
        handleDetailModalOpen();
      },
      hidden: !permissions[Permissions.PAGES_HOST_EMAIL_HISTORY_DETAILS],
    },
    ...(historyData.isSensitive
      ? []
      : [
          {
            dataCy: 'menu-item-resend-button',
            name: 'Resend',
            actionOnClick: () => {
              handleResendModalOpen();
            },
            hidden: !permissions[Permissions.PAGES_HOST_EMAIL_HISTORY_RESEND],
          },
        ]),
  ];

  return (
    <>
      <EmailDetailModal
        isSensitive={historyData.isSensitive}
        bodyObject={historyData.body}
        onClose={handleDetailModalClose}
        isOpen={isDetailModalOpen}
      />
      <EmailResendModal
        historyData={historyData}
        userAdminData={userAdminData}
        onClose={handleResendModalClose}
        isOpen={isResendModalOpen}
      />

      <TableRow dataCy="email-history-table-row">
        <TableData dataCy="table-data-email-history-subject">{historyData.subject || NO_DATA}</TableData>
        <TableData dataCy="table-data-email-history-sender">{historyData.sender || NO_DATA}</TableData>
        <TableData dataCy="table-data-email-history-receiver">{historyData.receiver || NO_DATA}</TableData>
        <TableData dataCy="table-data-email-history-receiver-email">{historyData.receiverEmail || NO_DATA}</TableData>
        <TableData dataCy="table-data-email-history-receiver-company">
          {historyData.receiverCompany || NO_DATA}
        </TableData>
        <TableData dataCy="table-data-email-history-is-sensitive">
          <Checkbox dataCy="is-active-checkbox" checked={historyData.isSensitive} disabled />
        </TableData>
        <TableData dataCy="table-data-email-history-status">
          {STATUS_DATA.find((item) => item.id === historyData.status)?.name || ''}
        </TableData>
        <TableData dataCy="table-data-email-history-created-date">
          {getFormattedDate(historyData.createdDate, DAY_FORMAT_WITH_TIME)}
        </TableData>
        <TableData>
          <DropdownMenu elements={menuItemsAction} />
        </TableData>
      </TableRow>
    </>
  );
};
export default EmailHistoryRow;
