import { ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

import { LANGUAGE_VARIANTS } from 'constants/common';
import { Currency, getFormatPrice, getFormatPriceUsingGBP } from 'constants/global';
import { DATA_CY_REGEX_PATTERN } from 'constants/subscription-tenant';
import { useAppSelector } from 'modules/store';
import { selectLanguagesSettings } from 'modules/user/selector';
export const getUniqueKeys = <T, K extends keyof T>(keysList: T[], key: K): string[] => {
  return Array.from(new Set(keysList.map((item) => String(item[key]))));
};

export const getPossibleKeysFromArray = (keysList: string[]): string[] => {
  return Array.from(
    new Set(
      keysList.flatMap((key) => {
        const parts = key.split('.');
        return parts.map((_, index) => parts.slice(0, index + 1).join('.'));
      })
    )
  );
};

export const filterDuplicatedFromArray = <T>(array: string[], secondArray: T[], keyProperty: keyof T & string): T[] => {
  return array.flatMap((key) => secondArray.filter((item) => String(item[keyProperty]) === key));
};

export const formatObjectsArrToStringWithSeparator = <T>(array: T[], key: keyof T, separator?: string): string => {
  if (!array?.length) {
    return '';
  }

  return array.map((obj) => String(obj[key])).join(separator || ', ');
};

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};

export const downloadDocument = async (context: string | Blob, fileName: string, fileType?: string) => {
  const safeContext = typeof context === 'string' ? context : '';
  const cleanedContext = safeContext.replace(/\s/g, '');
  const byteCharacters = atob(cleanedContext);
  const byteNumbers = Array.from(byteCharacters).map((char) => char.charCodeAt(0));
  const byteArray = new Uint8Array(byteNumbers);

  const url = URL.createObjectURL(
    new Blob([byteArray], {
      type: fileType,
    })
  );

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const capitalizeFirstLetter = (zxc: string) => {
  return zxc.charAt(0).toUpperCase() + zxc.slice(1).toLowerCase();
};

export const useFormatPriceFunction = (price: number, currency: Currency = Currency.GBP): string => {
  const { currentLanguage } = useAppSelector(selectLanguagesSettings);
  const formatPrice = currentLanguage?.name === LANGUAGE_VARIANTS.UA ? getFormatPriceUsingGBP : getFormatPrice;
  if (price == null) {
    return '';
  }
  return formatPrice(price, currency);
};

export const getRandomColor = (): string => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const formatDataCy = (label: string) => {
  return label.replace(DATA_CY_REGEX_PATTERN, '-').toLowerCase();
};

export const cleanHTMLContent = (htmlString: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  const styleTags = doc.querySelectorAll('style');
  styleTags.forEach((tag) => tag.remove());

  const elementsWithStyle = doc.querySelectorAll('[style]');
  elementsWithStyle.forEach((el) => el.removeAttribute('style'));

  return doc.body.innerHTML;
};
