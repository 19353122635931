import {
  EmailHistoryResponse,
  EmailHistoryStatusesResponse,
  ResendPayload,
  UserAdminResponse,
} from 'interfaces/email-history.interface';
import { CommonResponse } from 'interfaces/shared.interface';

import { coreSplitApis } from './core.api';

export const emailHistoryApi = coreSplitApis.enhanceEndpoints({ addTagTypes: ['EmailHistory'] }).injectEndpoints({
  endpoints: (build) => ({
    getEmailHistoryStatuses: build.query<EmailHistoryStatusesResponse, void>({
      query: () => ({
        url: `EmailHistoryStatuses/GetAll`,
        method: 'GET',
      }),
    }),
    getEmailHistory: build.query<EmailHistoryResponse, string>({
      query: (params) => ({
        url: `EmailHistory/GetAll?${params}`,
        method: 'GET',
      }),
      providesTags: ['EmailHistory'],
    }),
    getUserAdmin: build.query<UserAdminResponse, void>({
      query: () => ({
        url: `UserAdmin/GetAllUserEmails`,
        method: 'GET',
      }),
    }),
    postResend: build.mutation<CommonResponse, ResendPayload>({
      query: (data) => ({
        url: `EmailHistory/Resend`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (_, error) => (error ? [] : ['EmailHistory']),
    }),
  }),
  overrideExisting: false,
});

export const { useGetEmailHistoryStatusesQuery, useGetEmailHistoryQuery, useGetUserAdminQuery, usePostResendMutation } =
  emailHistoryApi;
