import { useTranslation } from 'react-i18next';

import { Modal } from 'shared-components';
import ModalControlButtons from 'shared-components/ModalControlButtons';
import { cleanHTMLContent } from 'utils/global';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  bodyObject: string;
  isSensitive?: boolean;
}

const EmailDetailModal = ({ isOpen, onClose, bodyObject, isSensitive }: Props) => {
  const { t } = useTranslation();

  const sanitizedBodyObject = cleanHTMLContent(bodyObject);

  return (
    <Modal heading={t('Details')} isDisableOutsideClick isOpen={isOpen} onClose={onClose}>
      <span className="w-full text-sm my-4">{t('Body')}:</span>
      <div data-cy="email-body-object" className="w-full mb-4 text-center email-modal-content">
        {isSensitive ? (
          <div>{t('SensitiveInformationPresent')}</div>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: sanitizedBodyObject }} />
        )}
      </div>
      <ModalControlButtons onClose={onClose} closeButtonText="Close" className="justify-end" />
    </Modal>
  );
};

export default EmailDetailModal;
