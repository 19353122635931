import { t } from 'i18next';
import { toast, ToastOptions } from 'react-toastify';

export const successNotify = (message?: string, options?: ToastOptions) =>
  toast.success(t(message || 'Success'), options);

export const infoNotify = (message?: string, options?: ToastOptions) => toast.info(t(message || 'Info'), options);

export const warningNotify = (message?: string, options?: ToastOptions) =>
  toast.warning(t(message || 'Warning'), options);

export const errorNotify = (message?: string, options?: ToastOptions) => toast.error(t(message || 'Error'), options);
