import { useEffect, useMemo } from 'react';

import { format } from 'date-fns';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useLazyAdminGetIncomeStatisticsQuery } from 'apis';
import { AREA_CHART_FILTER_FORM_FILTER_OPTIONS, AREA_CHART_FILTER_FORM_DEFAULT_VALUES } from 'constants/area-chart';
import { BoxVariants } from 'constants/common';
import { DASHBOARD_WIDGETS_BOX_HEADER_CLASSNAME } from 'constants/dashboard';
import { DAY_FORMAT_WITH_TIME_WITH_SLASHES, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES } from 'constants/global';
import { getSharedStackedAreaChartOptions } from 'constants/shared-components';
import { AREA_CHART_FILTERS_FORM_FIELDS_NAMES, AreaChartFilterFormValues } from 'interfaces/area-chart.interface';
import { IncomeStatistics } from 'interfaces/income-statistics.interface';
import { selectDateRange } from 'modules/dashboard/selector';
import { useAppSelector } from 'modules/store';
import { Box, LoadingOverlay } from 'shared-components';
import RadioInputSetV1 from 'shared-components/radio/RadioInputSetV1';
import { cn } from 'utils/global';
import { getFormattedChartDate } from 'utils/summary-sales';

export const IncomeStatisticsWidget = () => {
  const { t } = useTranslation();

  const { startDate, endDate } = useAppSelector(selectDateRange);

  const [getIncomeStatistics, { data, isLoading }] = useLazyAdminGetIncomeStatisticsQuery();

  const { incomeStatistics } = data?.result || ({} as IncomeStatistics);

  const incomeStatisticsAmount = incomeStatistics?.reduce((acc, { amount }) => acc + amount, 0);

  const { register, watch } = useForm<AreaChartFilterFormValues>({
    defaultValues: AREA_CHART_FILTER_FORM_DEFAULT_VALUES,
  });

  const watchIncomeStatisticsDatePeriod = watch(AREA_CHART_FILTERS_FORM_FIELDS_NAMES.AREA_CHART_DATE_PERIOD);

  const chartOptions = useMemo(() => {
    const categories = incomeStatistics?.map(({ date }) => getFormattedChartDate(date));

    const incomeStatisticsData = {
      name: '',
      pointStart: -0.5,
      data: incomeStatistics?.map(({ amount }) => amount),
      color: '#a8385d',
      showInLegend: false,
    };

    return getSharedStackedAreaChartOptions({
      categories,
      series: [incomeStatisticsData],
      showGrid: true,
      height: 215,
    });
  }, [incomeStatistics]);

  useEffect(() => {
    if (!watchIncomeStatisticsDatePeriod) {
      return;
    }

    getIncomeStatistics(
      {
        dateInterval: watchIncomeStatisticsDatePeriod,
        startDate: format(startDate, DAY_FORMAT_WITH_TIME_WITH_SLASHES),
        endDate: format(endDate, DAY_FORMAT_WITH_TIME_WITH_SLASHES),
      },
      true
    );
  }, [getIncomeStatistics, watchIncomeStatisticsDatePeriod, startDate, endDate]);

  return (
    <Box
      dataCy="income-statistics-widget"
      className="relative h-full overflow-auto"
      headerClassName={cn(
        DASHBOARD_WIDGETS_BOX_HEADER_CLASSNAME,
        'flex-col items-start md:items-center md:flex-row gap-2 md:gap-0 text-green4 whitespace-nowrap flex-wrap'
      )}
      variant={BoxVariants.ROUNDED}
      title="WidgetIncomeStatistics"
      headerWrapperClassName="items-baseline gap-0 xl:gap-1 flex-col xl:flex-row"
      customHeader={
        <RadioInputSetV1
          fieldSetClassName="justify-end"
          className="opacity-0 peer-checked:bg-black"
          {...register(AREA_CHART_FILTERS_FORM_FIELDS_NAMES.AREA_CHART_DATE_PERIOD)}
          radioOptions={AREA_CHART_FILTER_FORM_FILTER_OPTIONS}
        />
      }
      customSubHeader={
        <p className="text-xs text-gray2 flex flex-wrap gap-1">
          <span>{format(startDate, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES)} </span>-
          <span> {format(endDate, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES)}</span>
        </p>
      }
    >
      <LoadingOverlay isLoading={isLoading} />
      <div data-cy="income-statistics-chart-container">
        {incomeStatisticsAmount ? (
          <HighchartsReact
            containerProps={{ style: { height: '100%' } }}
            highcharts={Highcharts}
            options={chartOptions}
          />
        ) : (
          <p className="w-full text-center text-gray2 text-xs" data-cy="subscription-statistics-no-data">
            {t('NoData')}
          </p>
        )}
      </div>
    </Box>
  );
};
