import { FC, ReactNode, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';
import { Link } from 'react-router-dom';

import {
  useGetCurrentLoginInformationsQuery,
  useGetCurrentUserDetailsQuery,
  useGetUserConfigQuery,
  useGetUserProfilePictureQuery,
} from 'apis/user.api';
import { ReactComponent as LogoSvg } from 'assets/svg/logo.svg';
import { HOME_PAGE_YEAR } from 'constants/home';
import useImpersonateUser from 'hooks/useImpersonateUser';
import { useOpen } from 'hooks/useOpen';
import { CommonError } from 'interfaces/shared.interface';
import { useAppSelector } from 'modules/store';
import { selectTenantId } from 'modules/user/selector';
import ProfileSettingsModalContent from 'page-components/profile-settings/ProfileSettingsModalContent';
import { Header, SettingsDropdown, Modal, Sidebar, LoadingOverlay, ErrorModal } from 'shared-components';
import { cn } from 'utils/global';

interface Props {
  children?: ReactNode;
  childrenClassName?: string;
}

const ApplicationLayout: FC<Props> = ({ childrenClassName }) => {
  const { t } = useTranslation();
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [nestedModalIsOpened, setNestedModalIsOpened] = useState(true);
  const [isOpenSettingsModal, handleOpenSettingsModal, handleCloseSettingsModal] = useOpen();
  const [isErrorModalOpen, handleOpenErrorModal, handleOnCloseErrorModal] = useOpen();

  const { handleImpersonator, isFetching } = useImpersonateUser({ onError: handleOpenErrorModal });

  const {
    data: currentUserDetails,
    error: currentUserError,
    isLoading: isCurrentUserLoading,
  } = useGetCurrentUserDetailsQuery();

  const { data, error: currentLoginInfoError } = useGetCurrentLoginInformationsQuery();
  const { name, surname } = data?.user || {};

  useGetUserConfigQuery();

  const tenantId = useAppSelector(selectTenantId);

  const {
    data: pictureData,
    isLoading: isProfilePictureLoading,
    error: userProfilePictureError,
  } = useGetUserProfilePictureQuery(
    { profilePictureId: currentUserDetails?.profilePictureId || '', tenantId: tenantId || 0 },
    {
      skip: !currentUserDetails?.profilePictureId,
    }
  );

  const errorCurentUserData = (currentUserError as CommonError)?.data || {};

  const errorCurrentLoginInfoData = (currentLoginInfoError as CommonError)?.data || {};

  const errorUserProfilePictureErrorData = (userProfilePictureError as CommonError)?.data || {};

  const errorData = errorCurentUserData || errorCurrentLoginInfoData || errorUserProfilePictureErrorData;

  const toggleSidebar = () => {
    setSidebarVisible((prevState) => !prevState);
  };

  const handleNestedModalIsOpenedChange = (isNested: boolean) => setNestedModalIsOpened(isNested);

  return (
    <div className="flex relative">
      {isSidebarVisible && (
        <div
          className="fixed block lg:hidden inset-0 bg-black bg-opacity-30 lg:bg-opacity-0 z-20"
          onClick={() => setSidebarVisible(false)}
        />
      )}
      <div
        data-cy="sidebar-block"
        className={cn(
          'sidebar fixed flex-shrink-0 overflow-y-auto bg-darkBlue8 dark:bg-darkBlue transition-width duration-200 h-screen flex flex-col items-center shadow-[2px_0_15px_-5px_rgba(0,0,0,0.5)] z-30',
          {
            'w-64': isSidebarVisible,
            'w-16': !isSidebarVisible,
            'hidden lg:block': !isSidebarVisible,
          }
        )}
      >
        <div className="h-28 w-full flex items-center justify-center">
          <a href="/">
            <LogoSvg className={cn({ 'w-[90px]': isSidebarVisible, 'w-50': !isSidebarVisible })} />
          </a>
        </div>
        {data ? <Sidebar isSidebarVisible={isSidebarVisible} userType={data?.tenant ? 'tenant' : 'admin'} /> : ''}
        <div
          className={cn('flex text-white text-xs pb-3.5', { 'lg:flex': isSidebarVisible, hidden: !isSidebarVisible })}
        >
          <b className="mr-0.5">
            {' '}
            {t('Copyright')}. &copy; {HOME_PAGE_YEAR}
          </b>
          <Link to="#" className="text-gray dark:text-lightBlue">
            {t('EasyGo')}.
          </Link>
        </div>
      </div>

      <div
        className={cn('flex flex-col w-full duration-200', {
          'lg:ml-64': isSidebarVisible,
          'ml-0 lg:ml-16 duration-0': !isSidebarVisible,
        })}
      >
        <Header
          pictureData={pictureData}
          isProfilePictureLoading={isProfilePictureLoading || isCurrentUserLoading}
          isSidebarVisible={isSidebarVisible}
          onClick={toggleSidebar}
        >
          <SettingsDropdown
            firstName={name}
            lastName={surname}
            handleOpenSettingsModal={handleOpenSettingsModal}
            className="animate-padding"
            isImpersonatorUser={!!data?.impersonatorUser}
            handleBackToImpersonator={handleImpersonator}
          />
        </Header>
        <main className={cn('flex-grow', childrenClassName)}>
          <Outlet />
        </main>
      </div>
      <LoadingOverlay isLoading={isFetching} isFixed />
      <ErrorModal
        errorMessage={errorData?.error?.message}
        description={errorData?.error?.details}
        isOpen={isErrorModalOpen}
        handleClose={handleOnCloseErrorModal}
      />
      <Modal
        heading={t('AccountSettings')}
        isOpen={isOpenSettingsModal}
        onClose={handleCloseSettingsModal}
        isDisableOutsideClick={nestedModalIsOpened}
      >
        <ProfileSettingsModalContent
          handleClose={handleCloseSettingsModal}
          handleNestedModalIsOpenedChange={handleNestedModalIsOpenedChange}
        />
      </Modal>
    </div>
  );
};

export default ApplicationLayout;
