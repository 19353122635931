import { Fragment } from 'react';

import { faEdit, faPlus, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Layout } from 'react-grid-layout';
import { useTranslation } from 'react-i18next';

import { useDeleteDashboardPageMutation, useSaveDashboardLayoutMutation } from 'apis';
import { ReactComponent as ChevronUpSvg } from 'assets/svg/chevron-up.svg';
import i18n from 'configs/i18n';
import { useOpen } from 'hooks/useOpen';
import { DashboardAction, DashboardPage, DashboardWidget, DeletePageQueries } from 'interfaces/dashboard.interface';
import { setIsEditMode } from 'modules/dashboard/action';
import { useAppDispatch } from 'modules/store';
import { Button, WarningModal, ButtonWithPopUp } from 'shared-components';
import { convertGridToDashboardLayout } from 'utils/dashboard';
import { errorNotify, successNotify } from 'utils/notifications';

import { AddPage } from './AddPage';
import { AddWidget } from './AddWidget';
import { RenamePage } from './RenamePage';

const EDIT_ACTIONS_GROUPS = (
  widgets: DashboardWidget[],
  pageId: string,
  pageName: string,
  pagesCount: number,
  onSave: VoidFunction,
  onDefault: VoidFunction
): Record<string, DashboardAction[]> => ({
  widgets: [
    {
      id: 'addWidget',
      component: <AddWidget widgets={widgets} pageId={pageId} />,
    },
  ],
  pages: [
    {
      id: 'addPage',
      component: (
        <ButtonWithPopUp popUpContent={<AddPage />} popUpClassName="-top-32 max-w-20" dataCy="add-page-button">
          <FontAwesomeIcon icon={faPlus} />
          {i18n.t('AddPage')}
          <ChevronUpSvg className="fill-white w-4 h-4" />
        </ButtonWithPopUp>
      ),
    },
    {
      id: 'renamePage',
      component: (
        <ButtonWithPopUp
          popUpContent={<RenamePage pageId={pageId} pageName={pagesCount > 1 ? pageName : ''} />}
          popUpClassName="-top-32 max-w-20"
          dataCy="rename-page-button"
        >
          <FontAwesomeIcon icon={faEdit} />
          {i18n.t('RenamePage')}
          <ChevronUpSvg className="fill-white w-4 h-4" />
        </ButtonWithPopUp>
      ),
    },
    {
      id: 'backToDefaultPage',
      component: (
        <Button className="flex gap-1 items-center" onClick={onDefault} data-cy="dashboard-edit-default">
          <FontAwesomeIcon icon={faTrash} />
          {i18n.t(pagesCount > 1 ? 'DeletePage' : 'BackToDefaultPage')}
        </Button>
      ),
    },
  ],
  save: [
    {
      id: 'save',
      component: (
        <Button className="flex gap-1 items-center" onClick={onSave} data-cy="dashboard-edit-save">
          <FontAwesomeIcon icon={faSave} />
          {i18n.t('Save')}
        </Button>
      ),
    },
  ],
});

interface Props {
  dashboardName: string;
  pages: DashboardPage[];
  currentPage: Omit<DashboardPage, 'widgets'>;
  currentLayouts: Layout[];
}

export const EditActions = ({ currentLayouts, currentPage, pages, dashboardName }: Props) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const [saveDashboardLayout] = useSaveDashboardLayoutMutation();
  const [deleteDashboardPage] = useDeleteDashboardPageMutation();

  const [isOpenWarningModal, handleOpenWarningModal, handleCloseWarningModal] = useOpen();

  const widgets: DashboardWidget[] = currentLayouts.map(convertGridToDashboardLayout);

  const pageDeleteMessage = t(pages.length > 1 ? 'PageDeleteWarningMessage' : 'BackToDefaultPageWarningMessage', {
    0: currentPage?.name,
  });

  const onSave = async () => {
    const updatedPages = pages.map((page) =>
      page.id === currentPage.id
        ? {
            ...page,
            name: currentPage.name,
            widgets,
          }
        : page
    );

    const payload = {
      dashboardName,
      pages: updatedPages,
    };

    try {
      await saveDashboardLayout(payload).unwrap();
      dispatch(setIsEditMode(false));
      successNotify(t('SavedSuccessfully'));
    } catch (error) {
      errorNotify('ErrorWhileRequest');
    }
  };

  const onConfirmDefault = async () => {
    const payload: DeletePageQueries = {
      id: currentPage.id,
      dashboardName,
    };

    try {
      await deleteDashboardPage(payload).unwrap();
      handleCloseWarningModal();
      successNotify(t('SuccessfullyRemoved'));
    } catch (error) {
      errorNotify('ErrorWhileRequest');
    }
  };

  const actionGroups = EDIT_ACTIONS_GROUPS(
    widgets,
    currentPage.id,
    currentPage.name,
    pages.length,
    onSave,
    handleOpenWarningModal
  );

  return (
    <div
      className="bg-white dark:bg-darkBlue shadow-centerMedium dark:border dark:border-blackOpacity2 rounded-2lg p-4 fixed bottom-5 left-5 md:left-auto right-5 flex flex-wrap flex-col md:flex-row gap-4 md:gap-0"
      data-cy="dashboard-edit-actions"
    >
      {Object.keys(actionGroups).map((group) => (
        <div
          key={group}
          className="flex flex-1 md:flex-initial gap-1.5 md:border-r-1 last:border-r-0 md:px-3 first:pl-0 last:pr-0 border-darkGray3 [&>*]:w-full md:[&>*]:w-auto [&>*]:justify-center"
        >
          {actionGroups[group].map(({ id, component: Component }) => (
            <Fragment key={id}>{Component}</Fragment>
          ))}
        </div>
      ))}
      <WarningModal
        warningMessage={t('AreYouSure')}
        description={pageDeleteMessage}
        isOpen={isOpenWarningModal}
        handleClose={handleCloseWarningModal}
        handleConfirm={onConfirmDefault}
      />
    </div>
  );
};
