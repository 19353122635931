import { useMemo } from 'react';

import { format } from 'date-fns';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTranslation } from 'react-i18next';

import { useAdminGetSubscriptionStatisticsQuery } from 'apis';
import { BoxVariants } from 'constants/common';
import { DASHBOARD_WIDGETS_BOX_HEADER_CLASSNAME } from 'constants/dashboard';
import { DAY_MONTH_YEAR_FORMAT_WITH_SLASHES, DAY_FORMAT_WITH_TIME_WITH_SLASHES } from 'constants/global';
import { getPieChartOptions } from 'constants/shared-components';
import useTheme from 'hooks/useTheme';
import { ThemeConfig } from 'interfaces/user.interface';
import { selectDateRange } from 'modules/dashboard/selector';
import { useAppSelector } from 'modules/store';
import { Box, LoadingOverlay } from 'shared-components';
import { cn, getRandomColor } from 'utils/global';

export const SubscriptionStatisticsWidget = () => {
  const { t } = useTranslation();

  const { theme } = useTheme();

  const { startDate, endDate } = useAppSelector(selectDateRange);

  const {
    data: subscriptionStatisticsData,
    isLoading,
    isFetching,
  } = useAdminGetSubscriptionStatisticsQuery({
    startDate: format(startDate, DAY_FORMAT_WITH_TIME_WITH_SLASHES),
    endDate: format(endDate, DAY_FORMAT_WITH_TIME_WITH_SLASHES),
  });
  const subscriptionStatistics = subscriptionStatisticsData?.result.subscriptionStatistics;

  const colors = useMemo(() => {
    return subscriptionStatistics?.map(() => getRandomColor());
  }, [subscriptionStatistics]);

  const chartOptions = useMemo(() => {
    const chartData = subscriptionStatistics?.map(({ value, label }) => ({
      y: value,
      label,
    }));

    return getPieChartOptions(
      chartData ?? [],
      { colors, donutRadius: '25%', withTooltip: true, withLegend: true, height: 240 },
      theme === ThemeConfig.dark
    );
  }, [colors, subscriptionStatistics, theme]);

  return (
    <Box
      dataCy="subscription-statistics-widget"
      className="relative h-full overflow-auto"
      headerClassName={cn(
        DASHBOARD_WIDGETS_BOX_HEADER_CLASSNAME,
        'justify-start items-baseline gap-2 flex-wrap text-green4 break-all mb-0 overflow-hidden'
      )}
      variant={BoxVariants.ROUNDED}
      title="WidgetSubscriptionStatistics"
      titleClassName="whitespace-nowrap"
      customHeader={
        <p className="text-xs text-gray2 flex flex-wrap gap-1">
          <span>{format(startDate, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES)} </span>-
          <span> {format(endDate, DAY_MONTH_YEAR_FORMAT_WITH_SLASHES)}</span>
        </p>
      }
    >
      <div className="flex flex-col lg:flex-row gap-4 lg:gap-0">
        <LoadingOverlay isLoading={isLoading || isFetching} />
        <div className="h-full w-full">
          {subscriptionStatistics?.length ? (
            <HighchartsReact
              containerProps={{ style: { height: '100%', width: '100%' } }}
              highcharts={Highcharts}
              options={chartOptions}
            />
          ) : (
            <p className="w-full text-center text-gray2 text-xs" data-cy="subscription-statistics-no-data">
              {t('NoData')}
            </p>
          )}
        </div>
      </div>
    </Box>
  );
};
