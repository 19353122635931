import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import generateRangePicker from 'antd/es/date-picker/generatePicker/generateRangePicker';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DAY_MONTH_YEAR_FORMAT_WITH_SLASHES } from 'constants/global';
import { FormItem } from 'interfaces/shared.interface';
import { ErrorMessage } from 'shared-components';
import { cn } from 'utils/global';

const RangePicker = generateRangePicker(dateFnsGenerateConfig);

interface Props<T extends FieldValues> extends Omit<FormItem, 'component' | 'placeholder'> {
  label?: string;
  error?: string;
  name: string;
  control: Control<T>;
  required?: boolean;
  icon?: IconDefinition;
  placeholder?: [string, string];
}

const RangeDatePickerInput = <T extends FieldValues>({
  className,
  name,
  dataCy,
  control,
  labelClassName,
  inputClassName,
  label,
  disabled,
  required,
  placeholder,
  error,
}: Props<T>) => {
  const { t } = useTranslation();

  const getPopupContainer = (trigger: HTMLElement) => trigger.parentElement as HTMLElement;

  return (
    <div data-cy={dataCy} className={className}>
      {label && (
        <label
          className={cn('block text-gray-700 mb-2 text-sm font-normal dark:text-gray', labelClassName, {
            'opacity-70': disabled,
          })}
          data-cy="label"
        >
          {t(label)}
          {label && required && ' *'}
        </label>
      )}
      <>
        <Controller
          name={name as Path<T>}
          control={control}
          render={({ field: { value, ...field } }) => (
            <RangePicker
              format={DAY_MONTH_YEAR_FORMAT_WITH_SLASHES}
              value={value ?? [undefined, undefined]}
              getPopupContainer={getPopupContainer}
              separator={<div className="dark:text-white">-</div>}
              className={cn(
                'date-range p-0 pl-3 w-full justify-end rounded !text-darkGray date-picker dark:bg-darkBlue4 dark:hover:bg-darkBlue4',
                { '!border-error focus:ring': !!error },
                inputClassName
              )}
              placeholder={placeholder ? [t(placeholder[0]), t(placeholder[1])] : undefined}
              {...field}
            />
          )}
        />
      </>
      {error && <ErrorMessage dataCy="error-message">{t(error)}</ErrorMessage>}
    </div>
  );
};

export default RangeDatePickerInput;
