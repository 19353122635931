import { useEffect } from 'react';

import { FieldError, useFormContext } from 'react-hook-form';

import { SubscriptionManagementPaymentFormFields } from 'constants/subscriptions-admin';
import { SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES } from 'interfaces/subscriptions-admin.interface';
import { Checkbox, FormContent, Input } from 'shared-components';

import SubscriptionManagementsRadioInputWithSelect from './SubscriptionManagementRadioInputWithSelect';

interface Props {
  isEditingType?: boolean;
  isLoading: boolean;
}
const SubscriptionPropertiesTab = ({ isEditingType, isLoading }: Props) => {
  const {
    register,
    watch,
    setValue,
    resetField,
    formState: { errors },
  } = useFormContext();

  const watchSubscriptionNotFree = watch(SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.IS_PAID);

  const watchPaymentsFields = watch([
    SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.DAILY_PRICE,
    SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.WEEKLY_PRICE,
    SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.MONTHLY_PRICE,
    SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.ANNUAL_PRICE,
  ]);
  const normalizedFields = watchPaymentsFields.map((field) => (field === '-' ? '' : field));

  const isAllPaymentsFieldsEmpty = normalizedFields.every((field) => !field);

  useEffect(() => {
    if (isLoading || !isEditingType) {
      return;
    }

    const hasAnyPaymentValue = normalizedFields.some((field) => !!field);

    setValue(SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.IS_PAID, hasAnyPaymentValue);
  }, [watchPaymentsFields, isEditingType, isLoading, normalizedFields, setValue]);

  useEffect(() => {
    if (!watchSubscriptionNotFree) {
      resetField(SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.DAILY_PRICE);
      resetField(SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.WEEKLY_PRICE);
      resetField(SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.MONTHLY_PRICE);
      resetField(SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.ANNUAL_PRICE);
      resetField(SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.TRIAL_DAY_COUNT);
      resetField(SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.WAITING_DAY_AFTER_EXPIRE);
      resetField(SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.ASSIGNED_SUBSCRIPTION);
    }
  }, [resetField, watchSubscriptionNotFree]);

  return (
    <>
      {isLoading ? (
        <>
          <Input isLoading={true} label="Name" required inputClassName="w-full text-sm" />
          <Checkbox label="SubscriptionNotFree" labelClassName="font-medium" className="mt-8" isLoading={true} />
        </>
      ) : (
        <div>
          <Input
            {...register(SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.DISPLAY_NAME)}
            error={(errors[SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.DISPLAY_NAME] as FieldError)?.message}
            label="Name"
            dataCy="display-name-field"
            inputClassName="w-full text-sm"
            required
          />

          <Checkbox
            {...register(SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.IS_PAID)}
            error={(errors[SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.IS_PAID] as FieldError)?.message}
            label="SubscriptionNotFree"
            labelClassName="font-medium"
            disabled={isEditingType}
            dataCy="is-paid-checkbox"
            className="mt-8"
          />
        </div>
      )}
      {!isAllPaymentsFieldsEmpty || watchSubscriptionNotFree ? (
        <div>
          <FormContent
            fields={SubscriptionManagementPaymentFormFields(isEditingType)}
            register={register}
            errors={errors}
          />
          {isEditingType && (
            <Checkbox
              {...register(SUBSCRIPTION_MANAGEMENT_FORM_FIELDS_NAMES.STATUS_ID)}
              label="IsDeactivated"
              dataCy="is-deactivated-checkbox"
              className="mt-4"
            />
          )}
          <SubscriptionManagementsRadioInputWithSelect isEditingType={isEditingType} />
        </div>
      ) : null}
    </>
  );
};

export default SubscriptionPropertiesTab;
