import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAddPageMutation } from 'apis';
import { ADD_PAGE_FORM_FIELDS } from 'constants/dashboard';
import useUserRole from 'hooks/useUserRole';
import { AddPageForm } from 'interfaces/dashboard.interface';
import { Button, FormContent } from 'shared-components';
import { getDashboardName } from 'utils/dashboard';
import { errorNotify, successNotify, warningNotify } from 'utils/notifications';

export const AddPage = () => {
  const { t } = useTranslation();

  const { isAdmin } = useUserRole();
  const dashboardName = getDashboardName(isAdmin);

  const [addPageRequest, { isLoading }] = useAddPageMutation();

  const addPageMethods = useForm<AddPageForm>();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = addPageMethods;

  const onSubmit = async ({ name }: AddPageForm) => {
    if (!name?.length) {
      warningNotify(t('PageNameCanNotBeEmpty'));
      return;
    }

    try {
      await addPageRequest({ name, dashboardName });
      successNotify(t('SavedSuccessfully'));
    } catch (error) {
      errorNotify('ErrorWhileRequest');
    }
  };

  return (
    <FormProvider {...addPageMethods}>
      <form className="flex flex-col gap-2" data-cy="add-page-popup-form" onSubmit={handleSubmit(onSubmit)}>
        <FormContent fields={ADD_PAGE_FORM_FIELDS} register={register} errors={errors} />
        <Button isLoading={isLoading} dataCy="submit-button">
          {t('Save')}
        </Button>
      </form>
    </FormProvider>
  );
};
