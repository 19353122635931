import { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { DashboardState } from 'interfaces/dashboard.interface';

import { setDateRange, setIsEditMode } from './action';

export function setIsEditModeReducer(builder: ActionReducerMapBuilder<DashboardState>) {
  builder.addCase(setIsEditMode, (state, { payload }) => {
    state.isEditMode = payload;
  });
}

export function setDateRangeReducer(builder: ActionReducerMapBuilder<DashboardState>) {
  builder.addCase(setDateRange, (state, { payload }) => {
    state.dateRange = payload;
  });
}
