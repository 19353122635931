import { FC } from 'react';

import { Controller, Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import StateManagedSelect, { default as SelectComponent, StylesConfig } from 'react-select';

import useTheme from 'hooks/useTheme';
import { FormItem, OptionsTypeValue, OptionType } from 'interfaces/shared.interface';
import { Skeleton, ErrorMessage } from 'shared-components/index';
import { cn } from 'utils/global';

import { getBackgroundColorForControl, getSelectStyles } from './Select';

export type Props = {
  control: Control;
  isMulti?: boolean;
  className?: string;
  labelClassName?: string;
  maxMenuHeight?: number;
  isSearchable?: boolean;
  anchorPrefix?: string;
  error?: string;
  required?: boolean;
  menuPlacement?: 'bottom' | 'top';
  isLoading?: boolean;
  inputClassName?: string;
  isFilter?: boolean;
  onSelectValue?: (value: OptionsTypeValue) => void;
  value?: string;
  onFocus?: VoidFunction;
  onBlur?: VoidFunction;
  setIsDropdownOpen?: (boolean: boolean) => void;
} & Partial<StateManagedSelect> &
  Omit<FormItem, 'component'>;

const SelectFromInput: FC<Props> = ({
  label,
  options,
  control,
  dataCy,
  name,
  labelClassName,
  inputClassName,
  placeholder,
  isSearchable = false,
  disabled,
  defaultValue,
  menuPlacement,
  maxMenuHeight = 200,
  onSelectValue,
  isClearable = false,
  className,
  isLoading,
  required,
  isFilter = false, //Specifies if SelectFormInput is used as a filter as it requires different styling needing to be passed down
  onBlur,
  onFocus,
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  const internalizedOptions = options?.map((option) => {
    return { value: option.value, label: t(option.label) };
  });

  const backgroundColor = getBackgroundColorForControl(theme, isFilter);
  const customStyles: StylesConfig<OptionType> = {
    ...getSelectStyles(theme),
    control: (provided) => ({
      ...provided,
      backgroundColor,
      fontSize: '0.875rem',
      '&:hover': {
        borderColor: '#4096ff',
      },
    }),
  };

  return (
    <div className={cn('w-full', className)}>
      <>
        {typeof label === 'string' ? (
          <label className={cn('text-sm text-gray-500 dark:text-gray', labelClassName)}>
            {t(label)}
            {label && required && ' *'}
          </label>
        ) : (
          ''
        )}
        {isLoading ? (
          <Skeleton className={cn('w-full min-h-[2.5rem]', inputClassName)} />
        ) : (
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ field, fieldState: { error } }) => (
              <>
                <SelectComponent
                  id={dataCy}
                  isSearchable={isSearchable}
                  options={internalizedOptions}
                  isClearable={isClearable}
                  isMulti={false}
                  placeholder={t(placeholder || '')}
                  menuPlacement={menuPlacement}
                  styles={customStyles}
                  maxMenuHeight={maxMenuHeight}
                  className={cn(
                    'select-input div-py-0',
                    {
                      'select-input-error': error,
                      '[&>div]:bg-gray2': disabled,
                    },
                    inputClassName
                  )}
                  {...field}
                  onChange={(value) => {
                    field.onChange(value);

                    if (onSelectValue) {
                      onSelectValue(value);
                    }
                  }}
                  value={Object.values(field?.value || {}).includes(null) ? null : field.value}
                  onFocus={onFocus}
                  onBlur={onBlur}
                />
                {error?.message && <ErrorMessage dataCy="select-error-message">{t(error.message)}</ErrorMessage>}
              </>
            )}
          />
        )}
      </>
    </div>
  );
};

export default SelectFromInput;
