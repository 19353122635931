import { BaseFilterValues, CommonResponse, OptionType } from './shared.interface';

export interface EmailHistoryResponse extends CommonResponse {
  result: {
    totalCount: number;
    items: EmailHistory[];
  };
}

export interface EmailHistory {
  body: string;
  createdDate: string;
  errorMessage: null | string;
  id: string;
  isSensitive: boolean;
  receiver: string;
  receiverCompany: string;
  receiverEmail: string;
  sender: string;
  status: number;
  subject: string;
}

export interface EmailHistoryStatusesResponse extends CommonResponse {
  result: EmailHistoryStatuses[];
}

export interface EmailHistoryStatuses {
  id: number;
  name: string;
}

export interface UserAdminResponse extends CommonResponse {
  result: UserAdminData[];
}

export interface UserAdminData {
  id: number;
  emailAddress: string;
}

export enum EMAIL_HISTORY_COLUMNS {
  SUBJECT = 'subject',
  SENDER = 'sender',
  RECEIVER = 'receiver',
  RECEIVER_EMAIL = 'receiverEmail',
  RECEIVER_COMPANY = 'receiverCompany',
  IS_SENSITIVE = 'isSensitive',
  STATUS = 'status',
  CREATION_TIME = 'createdDate',
  ACTIONS = 'actions',
}

export enum STATUS_ENUMS {
  CREATED = 'Created',
  PROCESSED = 'Processed',
  FAILED = 'Failed',
}

export enum EMAIL_HISTORY_FILTERS_FORM_FIELD_NAMES {
  IS_SENSITIVE = 'IsSensitive',
  SELECT_STATUS = 'SelectStatus',
  SELECT_RECEIVER_EMAIL = 'SelectReceiverEmail',
}

export interface EmailHistoryFilterFormValues extends BaseFilterValues {
  [EMAIL_HISTORY_FILTERS_FORM_FIELD_NAMES.IS_SENSITIVE]: OptionType;
  [EMAIL_HISTORY_FILTERS_FORM_FIELD_NAMES.SELECT_STATUS]: OptionType;
  [EMAIL_HISTORY_FILTERS_FORM_FIELD_NAMES.SELECT_RECEIVER_EMAIL]: OptionType;
}

export interface ResendPayload {
  id: string;
  newReceiverEmail?: string;
}
